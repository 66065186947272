<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content"></div>
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <div style="float: left; width: 94%">
            <el-form-item label="商户编号：">
              <el-input
                v-model="searchForm.meCode"
                placeholder="商户编号"
                size="medium"
                clearable
                @keyup.enter.native="search"
              ></el-input>
            </el-form-item>
            <el-form-item label="商户名称：">
              <el-input
                v-model="searchForm.meName"
                placeholder="商户名称"
                size="medium"
                clearable
                @keyup.enter.native="search"
              ></el-input>
            </el-form-item>

            <el-form-item label="商户类型：">
              <el-select v-model="searchForm.meType" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.meType"
                  :label="item.label"
                  :value="item.meType"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="search" icon="el-icon-search" size="medium">
                搜索
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="refresh" icon="el-icon-refresh" size="medium">
                重置
              </el-button>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button
              type="primary"
              @click="addMerchant"
              size="medium"
              v-if="hasAuth('sy:merchant:create')"
            >
              新增
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="merchantTable"
      :data="tableData"
      v-loading="isLoading"
      tooltip-effect="dark"
      border
      style="width: 100%"
      stripe
      size="small"
      :row-style="{ height: '10px' }"
      :cell-style="{ padding: '1px 0' }"
    >
      <!--<el-table-column type="selection" width="55" align="center"></el-table-column>-->
      <el-table-column
        type="index"
        label="序号"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="meCode"
        label="商户编号"
        align="center"
        width="140"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="meName"
        label="商户名称"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="商户类型" align="center" width="120">
        <template slot-scope="scope">
          <span>
            <el-tag v-if="scope.row.meType === 0" size="small">服务</el-tag>
            <el-tag v-if="scope.row.meType === 1" type="success" size="small"
              >餐饮</el-tag
            >
            <el-tag v-if="scope.row.meType === 2" type="info" size="small"
              >娱乐</el-tag
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="meContact"
        label="联系人"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mePhone"
        label="联系人电话"
        align="center"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="alName"
        label="所属商盟"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="meAddr"
        label="商户地址"
        align="center"
        width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="商户状态" align="center">
        <template slot-scope="scope">
          <span>
            <el-tag
              v-if="scope.row.meStat === 0"
              type="success"
              size="small"
              effect="dark"
              >正常</el-tag
            >
            <el-tag
              v-if="scope.row.meStat === 1"
              type="danger"
              size="small"
              effect="dark"
              >关闭</el-tag
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="meDt"
        label="加入联盟时间"
        align="center"
        width="140"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="icon" width="160" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)" v-if="hasAuth('sy:merchant:edit')">编辑</el-button>
          <el-divider direction="vertical" v-if="hasAuth('sy:merchant:edit')"></el-divider>
          <el-button
            type="text"
            @click="deleteMerchant(scope.row.meId)"
            v-if="hasAuth('sy:merchant:delete')"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      :title="isAdd ? '新增' : '修改-' + titleName + '商户'"
      :visible.sync="addDialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <div v-loading="dialogLoading" class="merchant-form">
        <el-form
          :model="ruleForm"
          :rules="ruleFormRules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="商户logo：" prop="meLogoFile">
            <el-upload
              action="#"
              list-type="picture-card"
              :on-preview="handleMeLogoPreview"
              :on-remove="handleMeLogoRemove"
              :auto-upload="false"
              :on-change="handleMeLogoChange"
              :class="objClass"
              :file-list="meLogoList"
              :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <!-- el-dialog为点击预览图的放大按钮后弹出来的框，在框内显示放大的图片 -->
            <el-dialog :visible.sync="imageDialogVisible" append-to-body>
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="商户名称：" prop="meName">
            <el-input
              v-model="ruleForm.meName"
              placeholder="请输入商户名称"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="商户简称：" prop="meAbb">
            <el-input
              v-model="ruleForm.meAbb"
              placeholder="请输入商户简称"
              maxlength="25"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人：" prop="meContact">
            <el-input
              v-model="ruleForm.meContact"
              placeholder="请输入联系人"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="mePhone">
            <el-input
              v-model="ruleForm.mePhone"
              placeholder="请输入联系电话"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="所在地区：" prop="meArea">
            <el-cascader
              v-model="ruleForm.meArea"
              :options="areaOptions"
              :props="{ value: 'regionId', label: 'regionName' }"
            ></el-cascader>
          </el-form-item>
          <!--TODO 所属商盟 当前就小睿一个商户，所以商盟暂时不做-->
          <el-form-item label="详细地址：" prop="meAddr">
            <el-input
              v-model="ruleForm.meAddr"
              placeholder="请输入详细地址"
              type="textarea"
              maxlength="500"
              show-word-limit
              :autosize="{ minRows: 3, maxRows: 6 }"
            ></el-input>
          </el-form-item>
          <!--TODO 地图选择器-->
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button type="primary" @click="onSubmit()">保存</el-button>
          <el-button
            @click="
              () => {
                this.addDialogVisible = false;
              }
            "
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/merchant";
import config from "../../config";
import userApi from "../../api/user";
export default {
  name: "MerchantList",
  data() {
    let checkTel = (rule, value, callback) => {
      if (!/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/.test(value)) {
        callback(new Error("请输入正确的电话号码!"));
      } else {
        callback();
      }
    };
    return {
      ranks: "",
      isAdd: true,
      editId: "",
      addDialogVisible: false,
      isLoading: false,
      dialogLoading: false,
      total: 10,
      size: 10,
      current: 1,
      tableData: [],
      titleName: "",
      options: [
        {
          meType: 0,
          label: "服务",
        },
        {
          meType: 1,
          label: "餐饮",
        },
        {
          meType: 2,
          label: "娱乐",
        },
      ],
      searchForm: {
        meCode: "",
        meName: "",
        meType: "",
      },
      dialogImageUrl: "",
      imageDialogVisible: false,
      meLogoList: [],
      objClass: {
        upLoadShow: true,
        upLoadHide: false,
      },
      ruleForm: {
        meLogoFile: null,
        meName: "",
        meAbb: "",
        meContact: "",
        mePhone: "",
        meArea: null,
        meAddr: "",
      },
      ruleFormRules: {
        meLogoFile: [
          { required: true, message: "请上传商户logo", trigger: "blur" },
        ],
        meName: [
          { required: true, message: "请输入商户名称", trigger: "blur" },
        ],
        meAbb: [{ required: true, message: "请输入商户简称", trigger: "blur" }],
        meContact: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        mePhone: [{ required: true, validator: checkTel, trigger: "blur" }],
        meArea: [
          { required: true, message: "请输入所在地区", trigger: "blur" },
        ],
        meAddr: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      areaOptions: [],
    };
  },
  created() {
    this.getTableList();
    this.getAllAreaInfo();
    userApi.userinfo().then((res) => {
      this.ranks = res.data.data.result.ranks;
    });
  },
  methods: {
    search() {
      this.current = 1;
      this.getTableList();
    },
    refresh() {
      this.searchForm = {
        meCode: "",
        meName: "",
        meType: "",
      };
      this.current = 1;
      this.getTableList();
    },
    getTableList() {
      this.isLoading = true;
      api
        .list({
          params: {
            meCode: this.searchForm.meCode,
            meName: this.searchForm.meName,
            meType: this.searchForm.meType,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.result.records;
          this.size = res.data.data.result.size;
          this.current = res.data.data.result.current;
          this.total = res.data.data.result.total;
          this.isLoading = false;
        });
    },
    getAllAreaInfo() {
      api.getAllArea().then((res) => {
        this.areaOptions = res.data.data.result;
      });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getTableList();
    },
    addMerchant() {
      this.resetForm("ruleForm");
      this.isAdd = true;
      this.addDialogVisible = true;
    },
    resetForm(formName) {
      if (this.$refs[formName] != null && this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
      this.ruleForm = {
        meLogoFile: null,
        meName: "",
        meAbb: "",
        meContact: "",
        mePhone: "",
        meArea: "",
        meAddr: "",
      };
      this.dialogImageUrl = "";
      this.meLogoList = [];
      this.objClass.upLoadShow = true;
      this.objClass.upLoadHide = false;
    },
    // 点击预览图的放大按钮后会触发handlePictureCardPreview
    handleMeLogoPreview(file) {
      this.dialogImageUrl = file.url;
      this.imageDialogVisible = true;
    },
    handleMeLogoRemove(file, fileList) {
      this.objClass.upLoadShow = true; //删除图片后显示上传框
      this.objClass.upLoadHide = false;
      this.dialogImageUrl = "";
      this.ruleForm.meLogoFile = null;
    },
    handleMeLogoChange(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // .jpg,.jpeg,.png,.pdf,.PDF
      const extension1 = testmsg === "jpg";
      const extension2 = testmsg === "jpeg";
      const extension3 = testmsg === "png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!extension1 && !extension2 && !extension3) {
        this.$message({
          message: "上传的图片只能是.jpg,.jpeg,.png格式!",
          type: "warning",
        });
        this.meLogoList = [];
      } else if (!isLt2M) {
        this.$message({
          message: "上传的图片大小不能超过2M!",
          type: "warning",
        });
        this.meLogoList = [];
      } else {
        this.objClass.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
        this.objClass.upLoadShow = false;
        this.dialogImageUrl = file.url;
        this.ruleForm.meLogoFile = file.raw;
      }
    },
    onSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          let formData = new FormData();
          formData.append("meLogoFile", this.ruleForm.meLogoFile);
          formData.append("meName", this.ruleForm.meName);
          formData.append("meAbb", this.ruleForm.meAbb);
          formData.append("meContact", this.ruleForm.meContact);
          formData.append("mePhone", this.ruleForm.mePhone);
          formData.append("meArea", this.ruleForm.meArea);
          formData.append("meAddr", this.ruleForm.meAddr);
          formData.append("meId", this.editId);
          api
            .changeMerchantInfo(this.isAdd ? "add" : "edit", formData)
            .then((res) => {
              if (res.data.code === 200) {
                this.$notify({
                  title: "操作成功",
                  message: "",
                  position: "bottom-right",
                  type: "success",
                });
                this.dialogLoading = false;
                this.addDialogVisible = false;
                this.getTableList();
              }
            })
            .catch((e) => {
              this.dialogLoading = false;
            });
        }
      });
    },
    edit(row) {
      this.isAdd = false;
      this.editId = row.meId;
      this.isLoading = true;
      api.getInfoById(row.meId).then((res) => {
        let merchantInfo = res.data.data.result;
        if (merchantInfo.meLogo) {
          let meLogoUrl = config.baseUrl + "/" + merchantInfo.meLogo;
          this.meLogoList = [{ url: meLogoUrl }];
          this.objClass.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
          this.objClass.upLoadShow = false;
          this.dialogImageUrl = meLogoUrl;
          this.ruleForm.meLogoFile = "noEdit";
        }
        this.ruleForm.meName = merchantInfo.meName;
        this.ruleForm.meAbb = merchantInfo.meAbb;
        this.ruleForm.meContact = merchantInfo.meContact;
        this.ruleForm.mePhone = merchantInfo.mePhone;
        this.titleName = merchantInfo.meName;
        if (merchantInfo.meArea) {
          this.ruleForm.meArea = merchantInfo.meArea.split(",");
        }
        this.ruleForm.meAddr = merchantInfo.meAddr;
        this.isLoading = false;
        this.addDialogVisible = true;
      });
    },
    deleteMerchant(meId) {
      this.$confirm("是否确认删除该商户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.deleteMerchantById(meId).then((res) => {
            if (res.data.code === 200) {
              this.$notify({
                title: "prefect",
                message: "操作成功",
                type: "success",
                position: "bottom-right",
              });
            } else {
              this.$notify({
                title: "prefect",
                message: "操作失败",
                type: "error",
                position: "bottom-right",
              });
            }
            this.search();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
<style>
/*当upLoadHide为true时，启用如下样式，即上传框的样式，若为false则不启用该样式*/
.merchant-form .upLoadHide .el-upload {
  display: none;
}
</style>
